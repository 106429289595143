import {
  AuditHistoryIcon,
  DataExportIcon,
  DataImportIcon,
  EeaDefinitionIcon,
  GlobalDefinitionIcon,
  JurisdictionManagementIcon,
  LocalDefinitionIcon,
  StatusIcon,
} from '@/assets/icons';

import { Paths, Views } from '@/router/router.config';
import { RouteRecordRaw } from 'vue-router';

import { getProductPermissions } from '@/config';
import { PortalProducts } from '@/config/constants';

const { ownerPermissions, collaboratorPermissions } = getProductPermissions(PortalProducts.XBTECH);

const xbtechRoutes: Array<RouteRecordRaw> = [
  {
    path: `/${Paths.XBTECH}`,
    name: PortalProducts.XBTECH,
    component: () => import(/* webpackChunkName: "xbtech" */'@/products/xbtech/XBTechLayout.vue'),
    redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.LOCAL_DEFINITIONS}` }),
    meta: {
      requiredPermissions: [...collaboratorPermissions],
    },
    children: [
      {
        path: Paths.LOCAL_DEFINITIONS,
        name: `${PortalProducts.XBTECH}:${Views.LOCAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.LOCAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "local-definitions-view" */'@/views/local-definitions/LocalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: LocalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.XBTECH}:${Views.LOCAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-local-definitions-view" */'@/views/local-definitions/ListLocalDefinitions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.XBTECH}:${Views.LOCAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-local-definitions-view" */'@/views/local-definitions/CreateLocalDefinition.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.GLOBAL_DEFINITIONS,
        name: `${PortalProducts.XBTECH}:${Views.GLOBAL_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.GLOBAL_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "global-definitions-view" */'@/views/global-definitions/GlobalDefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: GlobalDefinitionIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.XBTECH}:${Views.GLOBAL_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-global-definitions-view" */'@/views/global-definitions/pages/list'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.XBTECH}:${Views.GLOBAL_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-global-definitions-view" */'@/views/global-definitions/pages/create'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.XBTECH}:${Views.GLOBAL_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-global-definitions-view" */'@/views/global-definitions/pages/edit'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.EEA_DEFINITIONS,
        name: `${PortalProducts.XBTECH}:${Views.EEA_DEFINITIONS}`,
        redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.EEA_DEFINITIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "eea-definitions-view" */'@/views/eea-definitions/EEADefinitions.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: EeaDefinitionIcon,
        },
        children: [
          {
            path: Paths.EEA_DEFINITIONS,
            name: `${PortalProducts.XBTECH}:${Views.EEA_DEFINITIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-eea-definitions-view" */'@/views/eea-definitions/pages/list/ListEEADefinitions.vue'),
            meta: {
              requiredPermissions: ['xbtech-collaboration-owner'],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.XBTECH}:${Views.EEA_DEFINITIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-eea-definitions-view" */'@/views/eea-definitions/pages/create/CreateEEADefinition.vue'),
            meta: {
              requiredPermissions: ['xbtech-collaboration-owner'],
            },
          },
          {
            path: `${Paths.EDIT}/:definitionId`,
            name: `${PortalProducts.XBTECH}:${Views.EEA_DEFINITIONS_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-eea-definitions-view" */'@/views/eea-definitions/pages/edit/EditEEADefinition.vue'),
            meta: {
              requiredPermissions: ['xbtech-collaboration-owner'],
            },
          },
        ],
      },
      {
        path: Paths.JURISDICTION_MANAGEMENT,
        name: `${PortalProducts.XBTECH}:${Views.JURISDICTION_MANAGEMENT}`,
        redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.JURISDICTIONS_LIST}` }),
        component: () => import(/* webpackChunkName: "jurisdiction-management-view" */ '@/views/jurisdiction-management/JurisdictionManagement.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: JurisdictionManagementIcon,
        },
        children: [
          {
            path: Paths.LIST,
            name: `${PortalProducts.XBTECH}:${Views.JURISDICTIONS_LIST}`,
            component: () => import(/* webpackChunkName: "list-jurisdictions-view" */'@/views/jurisdiction-management/ListJurisdictions.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.XBTECH}:${Views.JURISDICTIONS_CREATE}`,
            component: () => import(/* webpackChunkName: "create-jurisdiction-form" */'@/views/jurisdiction-management/CreateJurisdiction.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.STATUS_TABLE,
        name: `${PortalProducts.XBTECH}:${Views.STATUS_TABLE}`,
        component: () => import(/* webpackChunkName: "status-table-view" */'@/views/status/StatusTable.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: StatusIcon,
        },
        redirect: {
          name: `${PortalProducts.XBTECH}:${Views.STATUS_TABLE_MANAGE}`,
        },
        children: [
          {
            path: Paths.MANAGE,
            name: `${PortalProducts.XBTECH}:${Views.STATUS_TABLE_MANAGE}`,
            component: () => import(/* webpackChunkName: "manage-status-table-view" */'@/views/manage-status/ManageStatusTable.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.EDIT}/:statusId`,
            name: `${PortalProducts.XBTECH}:${Views.STATUS_TABLE_EDIT}`,
            component: () => import(/* webpackChunkName: "edit-status-entry-view" */'@/views/status/EditStatusEntry.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
            props: (route) => ({
              statusId: route.params.statusId,
            }),
          },
          {
            path: Paths.CREATE,
            name: `${PortalProducts.XBTECH}:${Views.STATUS_TABLE_CREATE}`,
            component: () => import(/* webpackChunkName: "create-status-entry-view" */'@/views/status/CreateStatusEntry.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
        ],
      },
      {
        path: Paths.AUDIT_HISTORY,
        name: `${PortalProducts.XBTECH}:${Views.AUDIT_HISTORY}`,
        component: () => import(/* webpackChunkName: "audit-history-view" */'@/views/audit-history/AuditHistory.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: AuditHistoryIcon,
        },
      },
      {
        path: Paths.DATA_EXPORT,
        name: `${PortalProducts.XBTECH}:${Views.DATA_EXPORT}`,
        component: () => import(/* webpackChunkName: "audit-history-view" */'@/views/data-export/DataExport.vue'),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataExportIcon,
        },
      },
      {
        path: Paths.DATA_IMPORT,
        name: `${PortalProducts.XBTECH}:${Views.DATA_IMPORT}`,
        component: () => import(/* webpackChunkName: "data-import-view" */'@/views/data-import/DataImport.vue'),
        redirect: () => ({ name: `${PortalProducts.XBTECH}:${Views.DATA_IMPORT_LIST}` }),
        meta: {
          requiredPermissions: [...ownerPermissions],
          icon: DataImportIcon,
        },
        children: [
          {
            path: Paths.DATA_IMPORT_LIST,
            name: `${PortalProducts.XBTECH}:${Views.DATA_IMPORT_LIST}`,
            component: () => import(/* webpackChunkName: "list-data-import-view" */'@/views/data-import/ListDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.DATA_IMPORT_PUBLISH}/:id`,
            name: `${PortalProducts.XBTECH}:${Views.DATA_IMPORT_PUBLISH}`,
            component: () => import(/* webpackChunkName: "publish-file-import-view" */ '@/views/data-import/publish'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: Paths.DATA_IMPORT_CREATE,
            name: `${PortalProducts.XBTECH}:${Views.DATA_IMPORT_CREATE}`,
            component: () => import(/* webpackChunkName: "new-data-import-view" */'@/views/data-import/NewDataImport.vue'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_VALIDATION_REPORT}/:reference/:id`,
            name: `${PortalProducts.XBTECH}:${Views.FILE_IMPORT_VALIDATION_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/ValidationReport'),
            meta: {
              requiredPermissions: [...ownerPermissions],
            },
          },
          {
            path: `${Paths.FILE_IMPORT_PREAUDIT_REPORT}/:id`,
            name: `${PortalProducts.XBTECH}:${Views.FILE_IMPORT_PREAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Pre-Audit',
            },
          },
          {
            path: `${Paths.FILE_IMPORT_POSTAUDIT_REPORT}/:id`,
            name: `${PortalProducts.XBTECH}:${Views.FILE_IMPORT_POSTAUDIT_REPORT}`,
            component: () => import(/* webpackChunkName: "file-import-report-view" */ '@/views/data-import/components/AuditReports'),
            meta: {
              requiredPermissions: [...ownerPermissions],
              auditType: 'Post-Audit',
            },
          },
        ],
      },
    ],
  },
];

export default xbtechRoutes;
